<script>
import { sameAs } from 'vuelidate/lib/validators';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';

export default {
  components: { BasePasswordInput },
  head: {
    title: 'Changement de mot de passe',
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isLinkValid: true,
    password: '',
    passwordConfirm: '',
  }),
  validations: {
    passwordConfirm: { sameAs: sameAs('password') },
  },
  methods: {
    handle() {
      this.isLoading = true;
      const { password } = this;
      const { token } = this.$route.query;
      this.$store.dispatch('auth/resetPassword', { password, token })
        .then(() => {
          this.$buefy.dialog.alert('Votre mot de passe a bien été changé !');
          this.$router.push(this.to);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    if (!this.$route.query.token) {
      this.isLinkValid = false;
    }
  },
};
</script>

<template>
  <div>
    <form
      v-if="isLinkValid"
      class="p-5"
      @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        Changer de mot de passe
      </h1>
      <b-field label="Nouveau mot de passe">
        <BasePasswordInput
          v-model="password"
        />
      </b-field>
      <b-field
        label="Confirmer le mot de passe"
        v-bind="$getErrorProps($v.passwordConfirm, [{ name: 'isSame', message: 'Les mots de passe ne correspondent pas' }])">
        <b-input
          v-model="passwordConfirm"
          type="password"
          placeholder="(8 caractères minimum)"
          min="8"
          required
          password-reveal
        />
      </b-field>
      <div class="buttons">
        <b-button
          native-type="submit"
          type="is-primary is-custom"
          :loading="isLoading"
          expanded>
          Changer le mot de passe
        </b-button>
      </div>
    </form>
    <div v-else class="content p-5">
      <p>
        Ce lien de réinitialisation ne fonctionne pas ou plus.
      </p>
      <p>
        Veuillez refaire une demande de mot de passe oublié.
      </p>
    </div>
  </div>
</template>
