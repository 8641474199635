<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    passwordHintIsOpen: false,
  }),
  computed: {
    passwordHasValidations() {
      return {
        digit: (/[0-9]/).test(this.value),
        special: (/[^A-Za-z0-9]/).test(this.value),
        min: this.value.length >= 8,
      };
    },
  },
};
</script>

<template>
  <b-tooltip class="is-flex" :active="passwordHintIsOpen" always multilined>
    <template #content>
      <p class="has-text-left">
        <b-icon pack="far" :icon="passwordHasValidations.digit ? 'check-square' : 'square'" />
        Au moins 1 chiffre
        <br>
        <b-icon pack="far" :icon="passwordHasValidations.special ? 'check-square' : 'square'" />
        Au moins 1 caractère spécial
        <br>
        <b-icon pack="far" :icon="passwordHasValidations.min ? 'check-square' : 'square'" />
        Au moins 8 caractères
      </p>
    </template>
    <b-input
      :value="value"
      type="password"
      minlength="8"
      maxlength="32"
      placeholder="8 caractères minimum"
      pattern=".*([^A-Za-z0-9].*[0-9]|[0-9].*[^A-Za-z0-9]).*"
      :has-counter="false"
      required
      password-reveal
      expanded
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @focus="passwordHintIsOpen =  true"
      @blur="passwordHintIsOpen = false"
    />
  </b-tooltip>
</template>
