<script>
import ResetPasswordForm from '@shared/components/ResetPasswordForm.vue';

export default {
  head: {
    title: 'Changement de mot de passe',
  },
  components: { ResetPasswordForm },
};
</script>

<template>
  <section class="section">
    <div class="container w-640">
      <ResetPasswordForm :to="{name: 'login'}" />
    </div>
  </section>
</template>
